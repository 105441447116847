import React from 'react';
import Reverify from 'gatsby-theme-q3/src/pages/reverify';
import FormContainer from '../../components/FormContainer';
import HydrationFix from '../../components/HydrationFix';

const CustomReverify = (props) => (
  <HydrationFix>
    <FormContainer>
      <Reverify {...props} />
    </FormContainer>
  </HydrationFix>
);

export default CustomReverify;
